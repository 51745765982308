import React from 'react';
import AnimationLink from './animation_link.js';

import './social.css';

const Social = () => {
  return ( 
    <div className='papercactusgames__social-container'>
        <div className='papercactusgames__social-head-title'><h1>Links</h1></div>
      <div className='papercactusgames__social-background-gradient'>
        <div className='papercactusgames__social'>
          <div className='papercactus__socials-social_icon-disable'>
            <a>
              <i className="fa-brands fa-steam"/> Steam 
            </a>
          </div>
          <div className='papercactus__socials-social_icon-disable'>
          <a>
            <i className="fa-brands fa-itch-io"/> Itch 
          </a>
          </div>
                <AnimationLink
                  targetId={'socialActive_presskit'}
                  to={'/foxandshadow/presskit'}
                  dataValue={'PRESS KIT'}
                  faIcon={'fa-regular fa-newspaper'}
                /> 
                <AnimationLink
                  targetId={'socialActive_mailinglist'}
                  to={'#mailinglist'}
                  dataValue={'Mail List'}
                  faIcon={'fa-regular fa-envelope'}
                  isExternal={true}
                />
                <AnimationLink
                  targetId={'socialActive_discord'}
                  to={'https://discord.com/invite/XuXajDW'}
                  dataValue={'DISCORD'}
                  faIcon={'fa-brands fa-discord'}
                  isExternal={true}
                />
        </div>
      </div>
    </div>
  );
};

export default Social;